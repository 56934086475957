import React from "react"
import MainNav from "../../components/Main/MainNav/MainNav"
import MainNav_m from "../../components/Main/Header/MainNav_m"
import AboutContents from "../../components/About/AboutContents"
import Footer from "../../components/Main/Footer/Footer"
import withStyles from "@material-ui/core/styles/withStyles"
import componentsStyle from "../../assets/jss/material-kit-react/views/components"

function About(props) {
  const { classes, ...rest } = props
  return (
    <div style={{ backgroundColor: "white" }}>
        <MainNav />
        <MainNav_m />
        <AboutContents />
        <Footer />
    </div>
  )
}

export default withStyles(componentsStyle)(About)