import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/GreetingsBanner.png'
import main from '../../assets/img/Greetings/greetings.png'
import img01 from '../../assets/img/About/about01_05.png'
import img02 from '../../assets/img/About/about02_03.png'
import img03 from '../../assets/img/About/about03_03.png'

import square from '../../assets/img/Work01/square.png'

import { BrowserView, MobileView } from 'react-device-detect'

function Contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    blue: {
      color: 'blue',
    },
    pSize: {
      fontSize: '15px',
    },
    fontsize: {
      marginTop: '-3%',
      color: '#b3b3b3',
    },
    spanStyle: {
      width: '2%',
      height: '90%',
      paddingBottom: '1%',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        <script
          type="text/javascript"
          src="/jquery.mCustomScrollbar.css"
        ></script>
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section clclassNameass="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{ color: '#0a35f4' }}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>会社情報</dt>
                <dd>
                  <a href="/greetings">ご挨拶・会社理念</a>
                </dd>
                <dd>
                  <a href="/about">会社概要・組織図</a>
                </dd>
                <dd>
                  <a href="/history">会社沿革・主要取引先</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <h2 style={{ marginBottom: '0%' }}>
                  {' '}
                  <span>
                    <img src={square} className="Square"></img>
                  </span>{' '}
                  会社概要
                </h2>
                <BrowserView>
                  <table className="type05">
                    <tr>
                      <th scope="row">会　社</th>
                      <td>GHインテグレーション株式会社</td>
                    </tr>
                    <tr>
                      <th scope="row">設　立</th>
                      <td>2018年4月3日</td>
                    </tr>
                    <tr>
                      <th scope="row">役　員</th>
                      <td>代表取締役社長　錦織　劉一　</td>
                    </tr>
                    <tr>
                      <th scope="row">所 在 地</th>
                      <td>
                        ■ 本社
                        <pre />
                        <br />
                        〒 102-0094 <br />
                        東京都千代田区紀尾井町4-1 ニューオータニガーデンコート
                        22F
                        <br />
                        <br />
                        Tel : 03-6910-0947 / Fax : 03-6910-0948
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">資 本 金</th>
                      <td>2,000 万円</td>
                    </tr>
                    <tr>
                      <th scope="row">事業内容</th>
                      <td>
                        IT人材派遣及び委託業務、4G/5G 通信 ・
                        IoT、ネットワーク、システム、オープン系、
                        <br />
                        <br />
                        ソフトウェア系ハードウエア開発、A1開発、要件定義、基本設計、詳細設計、開発、構築、
                        <br />
                        <br />
                        運用、管理、テストエンジニア派遣及び委託業務
                      </td>
                    </tr>
                  </table>
                </BrowserView>
                <MobileView>
                  <div className="work04Img01div" style={{marginBottom:'2%'}}>
                  <img src={img01} className="work04Img01" />
                </div>
                </MobileView>
                <h2 style={{ marginBottom: '0%' }}>
                  {' '}
                  <span>
                    <img src={square} className="Square"></img>
                  </span>{' '}
                  組織図
                </h2>
                <div className="work04Img01div" style={{ marginBottom: '2%' }}>
                  <img src={img02} className="work04Img01" />
                </div>
                <h2 style={{ marginBottom: '0%' }}>
                  {' '}
                  <span>
                    <img src={square} className="Square"></img>
                  </span>{' '}
                  役員一覧
                </h2>
                <div className="work04Img01div">
                  <img src={img03} className="work04Img01" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <script></script>
      <div className="top"></div>
    </div>
  )
}

export default Contents
